@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'Nyata';
  font-style: normal;
  font-weight: 200 700;
  font-display: swap;
  src: url('./assets/fonts/nyata.woff') format('woff'),
  url('./assets/fonts/nyata.woff2') format('woff2');
}

.font-nyata {
  font-family: 'Nyata', sans-serif;
}